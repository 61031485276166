import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import Stocks, { Stock } from '../model'

const fixNull = item => {
  Object.entries(item).forEach(([key, val]) => {
    if (val === '-1') item[key] = null
  })
}

export default {
  namespaced: true,

  state: {
    Stocks: new Stocks(),
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    StockItems: state => state.Stocks.list,
    isEmpty: state => state.Stocks.list.length === 0,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    /**
     *
     * @param {*} param0
     * @param {*} to
     */
    async getStockItems({ state, commit, dispatch }, {to = 1, type = null, name = null, pageSize, sortExpressionList, itemGroupIds, stockItemIds, infinitePageSize = false}) {
      const loadingName = 'Stock/getStockItems'
      let PAGE_NUMBER
      switch (to) {
        case 'prev':
          PAGE_NUMBER = state.Page.number - 1
          break
        case 'next':
          PAGE_NUMBER = state.Page.number + 1
          break
        default:
          PAGE_NUMBER = to
          break
      }
      const params = {
        PageSize: infinitePageSize ? 999999999 : pageSize,
        PageNumber: PAGE_NUMBER,
        Type: !type ? null : type,
        Name: name,
        sortExpressionList,
        itemGroupIds,
        stockItemIds
      }

      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Stock/all', params)
        },
        success: result => {
          commit('UPDATE_STOCK_LIST', result.data)
          return result
        }
      })
      return results
    },

    /**
     *
     * @param {*} param0
     * @param {*} Id
     */
    async getStockItem({ state, commit, dispatch }, Id) {
      const loadingName = 'Stock/getStockItem'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Stock', {
            params: { Id }
          })
        },
        success: result => {
          result.data.stockItem = new Stock(result.data.stockItem)
          return result
        }
      })
      return results
    },

    /**
     *
     * @param {*} param0
     * @param {*} item
     */
    async createStockItem({ commit, dispatch }, item) {
      const loadingName = 'Stock/createStockItem'
      // fix null
      fixNull(item)
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Stock', item)
        },
        success: result => result
      })
      return results
    },

    /**
     *
     * @param {*} param0
     * @param {*} item
     */
    async updateStockItem({ commit }, item) {
      const loadingName = 'Stock/updateStockItem'
      // fix null
      fixNull(item)
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Stock', item)
        },
        success: result => result
      })
      return results
    },

    /**
     *
     * @param {*} param0
     * @param {*} Id
     */
    async deleteStockItem({ commit }, Id) {
      const loadingName = 'Stock/deleteStockItem'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Stock', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async suggestStockItems({ commit }, params) {
      const loadingName = 'Stock/suggestStockItems'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Stock/all', params)
        },
        success: result => result
      })
      return results
    },

    async generateBarcode({ commit }, payload) {
      const loadingName = 'generateBarcode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Stock/generatebarcode', payload)
        },
        success: result => result
      })
      return results
    },

    async searchStockItem({ commit }, params) {
      const loadingName = 'Stock/searchStockItem'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Stock/filter', params)
        },
        success: result => result
      })
      return results
    },

    async generateBarcodeList({ commit }, params) {
      const loadingName = 'generateBarcodeList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Stock/generatebarcode/list', params)
        },
        success: result => result
      })
      return results
    },

    async getNutritionList({ commit, dispatch }, {params}) {
      const loadingName = 'getNutritionList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Nutrition/food/filter', { params })
        },
        success: result => result
      })
      return results
    },

    async defaultStockItemPrice({ commit }, payload) {
      const loadingName = 'Stock/defaultStockItemPrice'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Despatch/detail/defaultstockitemprice', {
            params: payload
          })
        },
        success: result => result
      })
      return results
    },

    async checkStockItemName({ commit, dispatch }, params) {
      const loadingName = 'checkStockItemName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Stock/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async checkStockItemNumber({ commit }, params) {
      const loadingName = 'checkStockItemNumber'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Stock/check/itemnumber', { params })
        },
        success: result => result
      })
      return results
    },

    async checkBarcode({ commit }, params) {
      const loadingName = 'checkBarcode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Stock/check/barcode', { params })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.Stocks = new Stocks()
      state.Page.number = 1
      state.Page.size = 20
      state.Page.total = 0
    },

    UPDATE_STOCK_LIST(state, data) {
      state.Stocks.addItems(data.stockItemPageList)
      state.Page.number = data.pageNumber
      state.Page.total = data.totalCount
      state.Page.size = data.pageSize
    },

    ADD_STOCK_ITEM(state, item) {
      state.Stocks.addItem(item)
      // state.Page.total = state.totalCount + 1
    },

    UPDATE_STOCK_ITEM(state, item) {
      state.Stocks.updateItem(item)
    },

    DELETE_STOCK_ITEM(state, Id) {
      state.Stocks.deleteItem(Id)
      // state.Page.total = state.totalCount - 1
    }
  }
}
