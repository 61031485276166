var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Stocks.popup_EditTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Stocks.popup_CreateTitle")))],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _c("SelectedButtons", {
            attrs: { data: _vm.typeData },
            model: {
              value: _vm.formType,
              callback: function ($$v) {
                _vm.formType = $$v
              },
              expression: "formType",
            },
          }),
          _vm.isFetchingStockData
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-stock" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitStockForm(_vm.isEdit)
                    },
                  },
                },
                [
                  _c("SelectStockType", {
                    attrs: { disableAll: _vm.isEdit, data: _vm.stockTypeList },
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  }),
                  _c("div", { staticClass: "Form-item required m-top-20" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stocks.popup_FormField_StockItemName"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.stockItemNameValidationRules,
                              expression: "stockItemNameValidationRules",
                            },
                          ],
                          ref: "firstField",
                          staticClass: "txt-right-icon",
                          attrs: {
                            name: "name",
                            id: "input-stocks-form-item-name",
                            error: _vm.veeErrors.has("name"),
                            "data-vv-as": _vm.$t(
                              "Stocks.popup_FormField_StockItemName"
                            ),
                            isDelayInput: true,
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                        _c(
                          "Button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isPendingName,
                                expression: "isPendingName",
                              },
                            ],
                            attrs: {
                              id: "btn-stock-popup-loading-name",
                              variant: "icon loading",
                              tabindex: "-1",
                            },
                          },
                          [_c("Loading", { attrs: { theme: "disable" } })],
                          1
                        ),
                        _c("Button", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isValidateName,
                              expression: "isValidateName",
                            },
                          ],
                          attrs: {
                            id: "btn-stock-popup-ok-name",
                            variant: "icon check",
                            iconName: "icon-check",
                            iconClass: "icon-check",
                            justIcon: true,
                            tabindex: "-1",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("name"),
                              expression: "veeErrors.has('name')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("name") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isTypePurchase,
                          expression: "isTypePurchase",
                        },
                      ],
                      staticClass: "Form-item m-top-20 required",
                    },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Stocks.popupForm_Field_purchaseStockItemName"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.closePurchaseStockItemDropdown,
                              expression: "closePurchaseStockItemDropdown",
                            },
                          ],
                          staticClass: "relative",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "control form-item-select",
                              class: {
                                "purchase-dropdown-shadow":
                                  _vm.showPurchaseStockItemDropdown &&
                                  !_vm.isEmptyPurchaseStockItemList,
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.purchaseStockItemName,
                                    expression: "purchaseStockItemName",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      _vm.purchaseStockItemNameValidationRules,
                                    expression:
                                      "purchaseStockItemNameValidationRules",
                                  },
                                ],
                                ref: "purchaseStockItemName",
                                staticClass: "txt purchase-stock-item-input",
                                class: {
                                  "is-danger": _vm.veeErrors.has(
                                    "purchaseStockItemName"
                                  ),
                                  "clear-border-radius-bottom":
                                    _vm.showPurchaseStockItemDropdown &&
                                    !_vm.isEmptyPurchaseStockItemList,
                                },
                                attrs: {
                                  id: "input-price-quote-popup-field-purchaseStockItemName",
                                  name: "purchaseStockItemName",
                                  placeholder: _vm.purchaseStockItemPlaceHolder,
                                  type: "text",
                                  autocomplete: "off",
                                  "data-vv-as": _vm.$t(
                                    "Stocks.popupForm_Field_purchaseStockItemName"
                                  ),
                                  "data-vv-delay":
                                    _vm.$getConst("INPUT_DELAY_TIME"),
                                },
                                domProps: { value: _vm.purchaseStockItemName },
                                on: {
                                  click: _vm.changePurchaseStockItemDropdown,
                                  focus: _vm.clearPurchaseStockItemName,
                                  blur: _vm.clearPurchaseStockItemName,
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.addPurchaseStockItem.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.purchaseStockItemName =
                                      $event.target.value
                                  },
                                },
                              }),
                              _vm.isLoadingCheckPurchaseStockItemName
                                ? _c("Loading", { staticClass: "absolute" })
                                : _c("Icon", {
                                    attrs: { name: "icon-down-arrow" },
                                  }),
                            ],
                            1
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has(
                                  "purchaseStockItemName"
                                ),
                                expression:
                                  "veeErrors.has('purchaseStockItemName')",
                              },
                            ],
                            attrs: {
                              errorName: _vm.veeErrors.first(
                                "purchaseStockItemName"
                              ),
                            },
                          }),
                          !_vm.isEmptyPurchaseStockItemList &&
                          _vm.showPurchaseStockItemDropdown
                            ? _c(
                                "div",
                                {
                                  staticClass: "purchase-dropdown",
                                  class: {
                                    "purchase-dropdown-shadow":
                                      _vm.showPurchaseStockItemDropdown &&
                                      !_vm.isEmptyPurchaseStockItemList,
                                  },
                                },
                                _vm._l(
                                  _vm.form.purchaseStockItemList,
                                  function (purchaseStockItem, i) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "purchase-dropdown-item",
                                        attrs: {
                                          id: `purchase-stock-item-${i}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "purchase-dropdown-item-title",
                                            attrs: {
                                              id: `purchase-stock-item-title-${i}`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(purchaseStockItem.name)
                                            ),
                                          ]
                                        ),
                                        _c("Button", {
                                          staticClass:
                                            "purchase-dropdown-item-remove",
                                          attrs: {
                                            id: `purchase-stock-item-remove-${i}`,
                                            variant: "icon Popup-header-close",
                                            iconName: "icon-global-x",
                                            iconClass: "icon-global-x",
                                            justIcon: true,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removePurchaseStockItem(
                                                i
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm.form.purchaseStockItemList.length > 5 &&
                          _vm.showPurchaseStockItemDropdown
                            ? _c("div", {
                                staticClass: "purchase-dropdown-tranparency",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Stocks.popup_FormField_StockItemGroup"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "itemGroupId",
                            optionData: _vm.allItemGroups,
                            "data-vv-as": _vm.$t(
                              "Stocks.popup_FormField_StockItemGroup"
                            ),
                            error: _vm.veeErrors.has("itemGroupId"),
                            id: "select-stocks-popup-group",
                            optionIdName: "option-stocks-groups",
                          },
                          on: { change: _vm.changedItemGroup },
                          model: {
                            value: _vm.form.itemGroupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "itemGroupId", $$v)
                            },
                            expression: "form.itemGroupId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("itemGroupId"),
                              expression: "veeErrors.has('itemGroupId')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("itemGroupId"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stocks.popup_FormField_Tax"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "taxId",
                            optionData: _vm.Taxes,
                            "data-vv-as": _vm.$t("Stocks.popup_FormField_Tax"),
                            error: _vm.veeErrors.has("taxId"),
                            id: "select-stocks-tax-group",
                            optionIdName: "option-stocks-tax",
                          },
                          model: {
                            value: _vm.form.taxId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "taxId", $$v)
                            },
                            expression: "form.taxId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("taxId"),
                              expression: "veeErrors.has('taxId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("taxId") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Stocks.popup_FormField_salesTax"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control control form-item-select" },
                          [
                            _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                            _c("customSelectInput", {
                              attrs: {
                                name: "salesTaxId",
                                optionData: _vm.Taxes,
                                "data-vv-as": _vm.$t(
                                  "Stocks.popup_FormField_salesTax"
                                ),
                                error: _vm.veeErrors.has("salesTaxId"),
                                id: "select-stocks-sales-tax-group",
                                optionIdName: "option-stocks-sales-tax",
                              },
                              model: {
                                value: _vm.form.salesTaxId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "salesTaxId", $$v)
                                },
                                expression: "form.salesTaxId",
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("salesTaxId"),
                                  expression: "veeErrors.has('salesTaxId')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("salesTaxId"),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Stocks.popup_FormField_priceType"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control control form-item-select" },
                          [
                            _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                            _c("customSelectInput", {
                              attrs: {
                                name: "priceType",
                                optionData: _vm.priceTypes,
                                optionKey: "value",
                                optionIdKey: "value",
                                isValueNumber: true,
                                "data-vv-as": _vm.$t(
                                  "Stocks.popup_FormField_priceType"
                                ),
                                error: _vm.veeErrors.has("priceType"),
                                hideDefault: true,
                                disabled: !_vm.checkPermission("PriceTypeEdit"),
                                id: "select-inventory-price-type",
                              },
                              model: {
                                value: _vm.form.priceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "priceType", $$v)
                                },
                                expression: "form.priceType",
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("priceType"),
                                  expression: "veeErrors.has('priceType')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("priceType"),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stocks.popup_FormField_BaseUnit"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          class: { "is-disabled": _vm.isEdit },
                          attrs: {
                            name: "baseUnitId",
                            optionData: _vm.BaseUnitList,
                            "data-vv-as": _vm.$t(
                              "Stocks.popup_FormField_BaseUnit"
                            ),
                            error: _vm.veeErrors.has("baseUnitId"),
                            disabled: _vm.isEdit,
                            id: "select-stocks-popup-baseunit",
                            optionIdName: "option-stocks-popup",
                          },
                          model: {
                            value: _vm.form.baseUnitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "baseUnitId", $$v)
                            },
                            expression: "form.baseUnitId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("baseUnitId"),
                              expression: "veeErrors.has('baseUnitId')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("baseUnitId"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.baseUnitId !== "-1"
                    ? _c("div", { staticClass: "Form-item m-top-20" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Stocks.popup_kgQuantity"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    greater_than: 0,
                                    decimal: _vm.$getConst(
                                      "DECIMAL_FOR_QUANTITY"
                                    ),
                                    max: _vm.$getConst(
                                      "MAX_DIGITS_FOR_QUANTITY"
                                    ),
                                  },
                                  expression:
                                    "{greater_than:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                },
                              ],
                              staticClass: "txt-right-icon",
                              attrs: {
                                name: "kgQuantity",
                                id: "input-kgQuantity-number",
                                error: _vm.veeErrors.has("kgQuantity"),
                                "data-vv-as": _vm.$t("Stocks.popup_kgQuantity"),
                                disabled: _vm.isUnitKg,
                              },
                              model: {
                                value: _vm.form.kgQuantity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "kgQuantity", $$v)
                                },
                                expression: "form.kgQuantity",
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("kgQuantity"),
                                  expression: "veeErrors.has('kgQuantity')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("kgQuantity"),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Stocks.popup_FormField_Unit"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "unitId",
                            optionData: _vm.filteredUnitList,
                            "data-vv-as": _vm.$t("Stocks.popup_FormField_Unit"),
                            error: _vm.veeErrors.has("unitId"),
                            id: "select-stocks-popup-group-unit",
                            optionIdName: "option-stocks-unit",
                          },
                          model: {
                            value: _vm.form.unitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unitId", $$v)
                            },
                            expression: "form.unitId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitId"),
                              expression: "veeErrors.has('unitId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("unitId") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.formType === 2 &&
                  _vm.checkPricePermission("StockItemsMenu")
                    ? _c("div", { staticClass: "Form-item" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Stocks.popup_FormField_lastPurchasePrice")
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min_value:0",
                                  expression: "'min_value:0'",
                                },
                              ],
                              staticClass: "txt-right-icon",
                              attrs: {
                                name: "lastPurchasePrice",
                                id: "input-stocks-form-item-number",
                                error: _vm.veeErrors.has("lastPurchasePrice"),
                                "data-vv-as": _vm.$t(
                                  "Stocks.popup_FormField_lastPurchasePrice"
                                ),
                                isDelayInput: true,
                              },
                              model: {
                                value: _vm.form.lastPurchasePrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "lastPurchasePrice", $$v)
                                },
                                expression: "form.lastPurchasePrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Stocks.popup_FormField_StockItemNumber")
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("customTextInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "verify_number",
                                  expression: "'verify_number'",
                                },
                              ],
                              staticClass: "txt-right-icon",
                              attrs: {
                                name: "itemNumber",
                                id: "input-stocks-form-item-number",
                                error: _vm.veeErrors.has("itemNumber"),
                                "data-vv-as": _vm.$t(
                                  "Stocks.popup_FormField_StockItemNumber"
                                ),
                                isDelayInput: true,
                              },
                              model: {
                                value: _vm.form.itemNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "itemNumber", $$v)
                                },
                                expression: "form.itemNumber",
                              },
                            }),
                            _c(
                              "Button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPendingNumber,
                                    expression: "isPendingNumber",
                                  },
                                ],
                                attrs: {
                                  id: "btn-stock-popup-loading-number",
                                  variant: "icon loading",
                                  tabindex: "-1",
                                },
                              },
                              [_c("Loading", { attrs: { theme: "disable" } })],
                              1
                            ),
                            _c("Button", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isValidateNumber,
                                  expression: "isValidateNumber",
                                },
                              ],
                              attrs: {
                                id: "btn-stock-popup-ok-number",
                                variant: "icon check",
                                tabindex: "-1",
                                iconName: "icon-check",
                                iconClass: "icon-check",
                                justIcon: true,
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("itemNumber"),
                                  expression: "veeErrors.has('itemNumber')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("itemNumber"),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  !_vm.isTypePurchase && _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item m-top-20" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Stocks.popup_barcodeEntry"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control barcode-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.barcode.barcode,
                                  expression: "barcode.barcode",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: `numeric|verify_barcode|verify_weighted_barcode:${_vm.form.baseUnitId}`,
                                  expression:
                                    "`numeric|verify_barcode|verify_weighted_barcode:${form.baseUnitId}`",
                                },
                              ],
                              staticClass: "txt",
                              class: {
                                "is-danger": _vm.veeErrors.has(`barcode`),
                              },
                              attrs: {
                                id: "input-price-quote-popup-field-barcode-stock-item-code",
                                type: "text",
                                name: "barcode",
                                autocomplete: "off",
                                placeholder: _vm.$t(
                                  "PriceQuota.popupForm_Field_barcode_Placeholder"
                                ),
                                disabled: _vm.disableBarcodeInput,
                                "data-vv-as": _vm.$t(
                                  "PriceQuota.popupForm_Field_barcode"
                                ),
                              },
                              domProps: { value: _vm.barcode.barcode },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.addBarcode.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.barcode,
                                    "barcode",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "barcode-actions" },
                              [
                                _vm.isLoadingCheckBarcode
                                  ? _c("Loading", {
                                      attrs: { theme: "disable" },
                                    })
                                  : _c(
                                      "Button",
                                      {
                                        attrs: {
                                          type: "button",
                                          size: "small",
                                          clearVariant: true,
                                          disabled:
                                            !_vm.barcode ||
                                            _vm.disableBarcodeInput ||
                                            _vm.disableBarcodeButton ||
                                            _vm.veeErrors.has(`barcode`),
                                        },
                                        on: { click: _vm.addBarcode },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "icon",
                                            class: [
                                              _vm.barcode &&
                                              !_vm.disableBarcodeInput &&
                                              !_vm.veeErrors.has(`barcode`)
                                                ? "c-success"
                                                : "c-gray",
                                            ],
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon-global-add",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("barcode"),
                                  expression: "veeErrors.has('barcode')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("barcode"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "barcodes" },
                          _vm._l(
                            _vm.form.barcodeList,
                            function (barcode, index) {
                              return _c(
                                "span",
                                { staticClass: "barcodes-text" },
                                [
                                  _vm._v(_vm._s(barcode.barcode)),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "barcodes-text-remove",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeBarcode(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("Icon", {
                                        attrs: { name: "icon-barcode-close" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  !_vm.isTypePurchase && _vm.formType === 2 && _vm.isEdit
                    ? _c("div", { staticClass: "Form-item m-top-20" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Stocks.popup_createBarcode"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control create-barcode" },
                          [
                            _c("CustomCheckbox", {
                              attrs: {
                                id: "input-stock-popup-field-barcode",
                                label: _vm.$t(
                                  "Stocks.popupForm_GenerateWeightedBarcode"
                                ),
                                disabled:
                                  _vm.disableBarcodeInput ||
                                  !_vm.isWeightedUnit(this.form.baseUnitId),
                              },
                              on: { change: _vm.changeBarcodeType },
                              model: {
                                value: _vm.form.isWeightedBarcode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isWeightedBarcode", $$v)
                                },
                                expression: "form.isWeightedBarcode",
                              },
                            }),
                            _c(
                              "Button",
                              {
                                attrs: {
                                  size: "small",
                                  primary: "",
                                  disabled:
                                    _vm.disableBarcodeInput ||
                                    _vm.disableBarcodeButton,
                                },
                                on: { click: _vm.createBarcode },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("Stocks.popup_createBarcode"))
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item flex" }, [
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("Stocks.popup_estimatedCost"))
                              ),
                            ]),
                            _c("customNumberInput", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    greater_than: 0,
                                    decimal: 2,
                                    max: _vm.$getConst(
                                      "MAX_DIGITS_FOR_QUANTITY"
                                    ),
                                  },
                                  expression:
                                    "{greater_than:0, decimal: 2, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                                },
                              ],
                              staticClass: "txt-right-icon",
                              attrs: {
                                name: "estimatedCost",
                                disabled:
                                  !_vm.checkPricePermission("StockItemsMenu"),
                                id: "input-estimated-cost-number",
                                error: _vm.veeErrors.has("estimatedCost"),
                                "data-vv-as": _vm.$t(
                                  "Stocks.popup_estimatedCost"
                                ),
                              },
                              model: {
                                value: _vm.form.estimatedCost,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "estimatedCost", $$v)
                                },
                                expression: "form.estimatedCost",
                              },
                            }),
                            _c("showValidateError", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.veeErrors.has("estimatedCost"),
                                  expression: "veeErrors.has('estimatedCost')",
                                },
                              ],
                              attrs: {
                                errorName: _vm.veeErrors.first("estimatedCost"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "m-left-5" },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(_vm._s(_vm.$t("Global.approvalLevel"))),
                            ]),
                            _c("customSelect", {
                              attrs: {
                                componentName: "stock-form-approval-level",
                                optionData: _vm.approvalLevels,
                                isDefaultTextActive: false,
                              },
                              model: {
                                value: _vm.form.approvalLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "approvalLevel", $$v)
                                },
                                expression: "form.approvalLevel",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "m-top-25" }, [
                        _c("div", { staticClass: "m-bottom-10" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Stocks.popup_getFromDeviationSettings")
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "price-deviation" }, [
                          _c("div", { staticClass: "Form-item" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Stocks.popup_minPriceDeviation")
                                      )
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "minPriceDeviation",
                                    id: "input-min-price-deviation",
                                    placeholder: ",00",
                                    disabled:
                                      _vm.checkPermission(
                                        "StockItemPriceDeviation"
                                      ) === false,
                                    isTypePercentage: true,
                                    error:
                                      _vm.veeErrors.has("minPriceDeviation"),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_minPriceDeviation"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.minPriceDeviation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "minPriceDeviation",
                                        $$v
                                      )
                                    },
                                    expression: "form.minPriceDeviation",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.veeErrors.has("minPriceDeviation"),
                                      expression:
                                        "veeErrors.has('minPriceDeviation')",
                                    },
                                  ],
                                  attrs: {
                                    errorName:
                                      _vm.veeErrors.first("minPriceDeviation"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "Form-item m-left-10" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Stocks.popup_maxPriceDeviation")
                                      )
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "maxPriceDeviation",
                                    id: "input-max-price-deviation",
                                    placeholder: ",00",
                                    disabled:
                                      _vm.checkPermission(
                                        "StockItemPriceDeviation"
                                      ) === false,
                                    isTypePercentage: true,
                                    error:
                                      _vm.veeErrors.has("maxPriceDeviation"),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_maxPriceDeviation"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.maxPriceDeviation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "maxPriceDeviation",
                                        $$v
                                      )
                                    },
                                    expression: "form.maxPriceDeviation",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.veeErrors.has("maxPriceDeviation"),
                                      expression:
                                        "veeErrors.has('maxPriceDeviation')",
                                    },
                                  ],
                                  attrs: {
                                    errorName:
                                      _vm.veeErrors.first("maxPriceDeviation"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "Form-item" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Stocks.popup_minQuantityDeviation"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "minQuantityDeviation",
                                    id: "input-min-quantity-deviation",
                                    isTypePercentage: true,
                                    placeholder: ",00",
                                    disabled:
                                      _vm.checkPermission(
                                        "StockItemQuantityDeviation"
                                      ) === false,
                                    error: _vm.veeErrors.has(
                                      "minQuantityDeviation"
                                    ),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_minQuantityDeviation"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.minQuantityDeviation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "minQuantityDeviation",
                                        $$v
                                      )
                                    },
                                    expression: "form.minQuantityDeviation",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has(
                                        "minQuantityDeviation"
                                      ),
                                      expression:
                                        "veeErrors.has('minQuantityDeviation')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first(
                                      "minQuantityDeviation"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "Form-item m-left-10" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Stocks.popup_maxQuantityDeviation"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "maxQuantityDeviation",
                                    id: "input-max-quantity-deviation",
                                    isTypePercentage: true,
                                    disabled:
                                      _vm.checkPermission(
                                        "StockItemQuantityDeviation"
                                      ) === false,
                                    placeholder: ",00",
                                    error: _vm.veeErrors.has(
                                      "maxQuantityDeviation"
                                    ),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_maxQuantityDeviation"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.maxQuantityDeviation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "maxQuantityDeviation",
                                        $$v
                                      )
                                    },
                                    expression: "form.maxQuantityDeviation",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has(
                                        "maxQuantityDeviation"
                                      ),
                                      expression:
                                        "veeErrors.has('maxQuantityDeviation')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first(
                                      "maxQuantityDeviation"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "m-top-25" }, [
                        _c("div", { staticClass: "m-bottom-10 m-top-25" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Stocks.popup_getWithoutDeviationSettings")
                            )
                          ),
                        ]),
                        _c("div", { staticClass: "price-deviation" }, [
                          _c("div", { staticClass: "Form-item" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Stocks.popup_minPriceDeviation")
                                      )
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "minPriceDeviationWithoutOrder",
                                    id: "input-min-price-devition-without-order",
                                    placeholder: ",00",
                                    disabled:
                                      _vm.checkPermission(
                                        "StockItemPriceDeviationWithoutOrder"
                                      ) === false,
                                    isTypePercentage: true,
                                    error: _vm.veeErrors.has(
                                      "minPriceDeviationWithoutOrder"
                                    ),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_minPriceDeviation"
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.form.minPriceDeviationWithoutOrder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "minPriceDeviationWithoutOrder",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.minPriceDeviationWithoutOrder",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has(
                                        "minPriceDeviationWithoutOrder"
                                      ),
                                      expression:
                                        "veeErrors.has('minPriceDeviationWithoutOrder')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first(
                                      "minPriceDeviationWithoutOrder"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "Form-item m-left-10" }, [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Stocks.popup_maxPriceDeviation")
                                      )
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:-1|max_value:100",
                                      expression:
                                        "'greater_than:-1|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "maxPriceDeviationWithoutOrder",
                                    id: "input-max-price-devition-without-order",
                                    placeholder: ",00",
                                    disabled:
                                      _vm.checkPermission(
                                        "StockItemPriceDeviationWithoutOrder"
                                      ) === false,
                                    isTypePercentage: true,
                                    error: _vm.veeErrors.has(
                                      "maxPriceDeviationWithoutOrder"
                                    ),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_maxPriceDeviation"
                                    ),
                                  },
                                  model: {
                                    value:
                                      _vm.form.maxPriceDeviationWithoutOrder,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "maxPriceDeviationWithoutOrder",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.maxPriceDeviationWithoutOrder",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has(
                                        "maxPriceDeviationWithoutOrder"
                                      ),
                                      expression:
                                        "veeErrors.has('maxPriceDeviationWithoutOrder')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first(
                                      "maxPriceDeviationWithoutOrder"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item m-top-25" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Stocks.popupForm_Allergen"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control form-item-select" },
                          [
                            _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.allergen,
                                    expression: "allergen",
                                  },
                                ],
                                staticClass: "select",
                                class: {
                                  "is-danger": _vm.veeErrors.has("allergen"),
                                },
                                attrs: {
                                  id: "select-stocks-allergen",
                                  name: "allergen",
                                  "data-vv-as": _vm.$t(
                                    "Stocks.popup_FormField_BaseUnit"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.allergen = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.addAllergen()
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      id: "option-allergen-unselected",
                                      value: "-1",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Stocks.popup_FormField_BaseUnit_Unselected"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.allergenListItems,
                                  function (allergen) {
                                    return _c(
                                      "option",
                                      {
                                        key: allergen.id,
                                        attrs: {
                                          id: `option-stocks-popup-allergen-${allergen.name}`,
                                        },
                                        domProps: { value: allergen },
                                      },
                                      [_vm._v(_vm._s(allergen.name))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("allergen"),
                                expression: "veeErrors.has('allergen')",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [_vm._v(_vm._s(_vm.veeErrors.first("allergen")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "barcodes" },
                          _vm._l(
                            _vm.allergenListSelected,
                            function (allergen, index) {
                              return _c(
                                "span",
                                { staticClass: "barcodes-text m-top-5" },
                                [
                                  _vm._v(_vm._s(allergen.name)),
                                  _c("Button", {
                                    staticClass: "barcodes-text-remove",
                                    attrs: {
                                      type: "button",
                                      size: "small",
                                      iconName: "icon-barcode-close",
                                      iconClass: "icon-barcode-close",
                                      clearVariant: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeAllergen(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm.formType === 2
                    ? _c("div", { staticClass: "Form-item" }, [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("Stocks.nutritionalValue"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("custom-search", {
                              attrs: {
                                name: "nutritionName",
                                "component-name": "form-nutrition-name",
                                placeholder: _vm.$t(
                                  "Recipes.detail_Popup_FormField_StockItemName_Placeholder"
                                ),
                                "on-search": _vm.getOptions,
                                options: _vm.selectOptions,
                                loading: _vm.isSearchNutrition,
                                label: "name",
                              },
                              on: { input: _vm.selectNutrition },
                              model: {
                                value: _vm.form.nutritionFoodName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "nutritionFoodName", $$v)
                                },
                                expression: "form.nutritionFoodName",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "selected-items m-top-10 m-bottom-10",
                              },
                              [
                                _vm.form.nutritionFoodName
                                  ? _c(
                                      "Button",
                                      {
                                        attrs: {
                                          size: "small",
                                          id: "selected-nutrition-list-item",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.removeNutrition.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.form.nutritionFoodName)
                                          ),
                                        ]),
                                        _c("Icon", {
                                          staticClass: "m-left-5",
                                          attrs: {
                                            name: "icon-popup-close",
                                            id: "selected-nutrition-list-item-icon",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "expiration-control m-top-25" }, [
                    _vm.formType === 2
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Form-item expiration-control-shelf-life m-right-20",
                          },
                          [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(_vm._s(_vm.$t("Stocks.popup_ShelfLife"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        min_value: 0,
                                        decimal: _vm.$getConst(
                                          "DECIMAL_FOR_QUANTITY"
                                        ),
                                      },
                                      expression:
                                        "{min_value:0, decimal: $getConst('DECIMAL_FOR_QUANTITY')}",
                                    },
                                  ],
                                  staticClass: "txt-right-icon",
                                  attrs: {
                                    name: "shelfLife",
                                    id: "input-shelf-life-number",
                                    error: _vm.veeErrors.has("shelfLife"),
                                    "data-vv-as": _vm.$t(
                                      "Stocks.popup_ShelfLife"
                                    ),
                                  },
                                  model: {
                                    value: _vm.form.shelfLife,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "shelfLife", $$v)
                                    },
                                    expression: "form.shelfLife",
                                  },
                                }),
                                _c("showValidateError", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.veeErrors.has("shelfLife"),
                                      expression: "veeErrors.has('shelfLife')",
                                    },
                                  ],
                                  attrs: {
                                    errorName: _vm.veeErrors.first("shelfLife"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.formType === 2
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "Form-item exp-date-control m-top-20 left-25",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("CustomCheckbox", {
                                  attrs: {
                                    id: "input-stock-popup-field-expiration-date-control",
                                    label: _vm.$t(
                                      "Stores.popupForm_ExpirationDateControl"
                                    ),
                                    disabled: _vm.isTypePurchase,
                                    checked:
                                      _vm.form.expirationDateControlIsEnabled &&
                                      !_vm.isTypePurchase,
                                  },
                                  on: {
                                    change: _vm.changeExpirationDateControl,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("custom-inputs", {
                    ref: "customInputs",
                    attrs: { tableType: 1, isEdit: _vm.isEdit },
                    model: {
                      value: _vm.customFields,
                      callback: function ($$v) {
                        _vm.customFields = $$v
                      },
                      expression: "customFields",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-stock-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Stocks.popup_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                id: "btn-stock-popup-submit",
                variant: "full",
                type: "submit",
                form: "form-stock",
                primary: "",
                size: "large",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Stocks.popup_FinishEditButton")))]
                    : [
                        _vm._v(
                          _vm._s(_vm.$t("Stocks.popup_FinishCreateButton"))
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }