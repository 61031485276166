<template lang="pug">
PopupView(
  :item="item",
  :fields="fields")
    template(slot="title") {{ item.name }}
</template>

<script>
import getFields from '../fields'

export default {
  name: 'StockView',

  data () {
    return {
      fields: getFields(this.$t),
      item: {
        name: ''
      }
    }
  },

  mounted () {
    this.item = this.$route.params.item
  }
}
</script>
