<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Stocks.remove_TableName')",
  loading="Stock/deleteStockItem",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import getFields from '../fields'

export default {
  name: 'StockRemove',

  data () {
    return {
      fields: getFields(this.$t),
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getStockItem(this.$route.params.id)
        .then(res => {
          this.item = res.data.stockItem
        })
    }
  },

  computed: {
    ...mapGetters('Stock', [
      'Page'
    ])
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stock', [
      'deleteStockItem',
      'getStockItem'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteStockItem(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })

          this.notifyShow({ message })
          this.$emit('getList', this.Page.number)
          this.close()
        }
      })
    }, 'stockRemoveHeader')
  }
}
</script>

<style lang="scss" scoped>
  :deep() .Alert-item {
    overflow-x: scroll;
  }
</style>
