var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PopupView",
    { attrs: { item: _vm.item, fields: _vm.fields } },
    [_c("template", { slot: "title" }, [_vm._v(_vm._s(_vm.item.name))])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }