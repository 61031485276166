import Index from '../'
import View from '../popup/view'
import Form from '../popup/form'
import Remove from '../popup/remove'

export default [
  {
    path: '/stock',
    name: 'Stock',
    component: Index,
    children: [
      {
        path: 'new',
        name: 'StockNew',
        component: Form
      },
      {
        path: ':id/edit',
        name: 'StockEdit',
        component: Form,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id',
        name: 'StockView',
        component: View
      },
      {
        path: ':id/remove',
        name: 'StockRemove',
        component: Remove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'stock',
      category: 'Main_data',
      permissionKey: 'StockItemsMenu',
      page: 'stock'
    }
  }
]
