<template lang="pug">
Popup

    template(slot="title")
      template(
        v-if="isEdit"
      ) {{ $t('Stocks.popup_EditTitle') }}
      template(
        v-else
      ) {{ $t('Stocks.popup_CreateTitle') }}

    template(slot="content")

      SelectedButtons(v-model="formType", :data="typeData")

      Loading(v-if="isFetchingStockData")
      form.Form(
      id="form-stock",
      v-else,
      @submit.prevent="onSubmitStockForm(isEdit)")

        SelectStockType(
          v-model="form.type"
          :disableAll="isEdit"
          :data="stockTypeList"
        )

        div.Form-item.required.m-top-20
          label.Form-item-label {{ $t('Stocks.popup_FormField_StockItemName') }}
          .control
            customTextInput.txt-right-icon(
              name="name"
              id="input-stocks-form-item-name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('Stocks.popup_FormField_StockItemName')"
              v-validate="stockItemNameValidationRules"
              v-model="form.name"
              :isDelayInput="true"
            )

            Button(
              id="btn-stock-popup-loading-name"
              variant="icon loading"
              tabindex="-1"
              v-show="isPendingName"
            )
              Loading(theme="disable")
            Button(
              id="btn-stock-popup-ok-name"
              variant="icon check"
              iconName="icon-check"
              iconClass="icon-check"
              :justIcon="true"
              tabindex="-1"
              v-show="isValidateName"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item.m-top-20.required(
          v-show="isTypePurchase"
        )
          label.Form-item-label {{ $t('Stocks.popupForm_Field_purchaseStockItemName') }}

          .relative(
            v-click-outside="closePurchaseStockItemDropdown"
          )
            .control.form-item-select(
              :class="{'purchase-dropdown-shadow': showPurchaseStockItemDropdown && !isEmptyPurchaseStockItemList}"
            )
              input.txt.purchase-stock-item-input(
                id="input-price-quote-popup-field-purchaseStockItemName"
                ref="purchaseStockItemName"
                name="purchaseStockItemName"
                @click="changePurchaseStockItemDropdown"
                :placeholder="purchaseStockItemPlaceHolder"
                type="text"
                :class="{ 'is-danger': veeErrors.has('purchaseStockItemName'),'clear-border-radius-bottom': showPurchaseStockItemDropdown && !isEmptyPurchaseStockItemList}"
                autocomplete="off"
                :data-vv-as="$t('Stocks.popupForm_Field_purchaseStockItemName')"
                v-model="purchaseStockItemName"
                @focus="clearPurchaseStockItemName"
                @blur="clearPurchaseStockItemName"
                v-validate="purchaseStockItemNameValidationRules"
                :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
                @keydown.prevent.enter="addPurchaseStockItem"
              )
              Loading.absolute(v-if="isLoadingCheckPurchaseStockItemName")
              Icon(v-else name="icon-down-arrow")
            
            showValidateError(
              v-show="veeErrors.has('purchaseStockItemName')"
              :errorName="veeErrors.first('purchaseStockItemName')"
            )
            .purchase-dropdown(
              v-if="!isEmptyPurchaseStockItemList && showPurchaseStockItemDropdown"
              :class="{'purchase-dropdown-shadow': showPurchaseStockItemDropdown && !isEmptyPurchaseStockItemList}"
            )
              .purchase-dropdown-item(
                v-for="(purchaseStockItem, i) in form.purchaseStockItemList"
                :id="`purchase-stock-item-${i}`"
                )
                span.purchase-dropdown-item-title(:id="`purchase-stock-item-title-${i}`") {{ purchaseStockItem.name }}
                Button.purchase-dropdown-item-remove(
                  :id="`purchase-stock-item-remove-${i}`"
                  variant     ="icon Popup-header-close",
                  @click      ="removePurchaseStockItem(i)",
                  iconName    ="icon-global-x",
                  iconClass   ="icon-global-x",
                  :justIcon   ="true"
                )

            .purchase-dropdown-tranparency(v-if="form.purchaseStockItemList.length > 5 && showPurchaseStockItemDropdown")

        div.Form-item.required
          label.Form-item-label {{ $t('Stocks.popup_FormField_StockItemGroup') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")


            customSelectInput(
              name="itemGroupId",
              :optionData="allItemGroups"
              v-model="form.itemGroupId"
              :data-vv-as="$t('Stocks.popup_FormField_StockItemGroup')"
              :error="veeErrors.has('itemGroupId')"
              v-validate="'required|is_not:-1'"
              id="select-stocks-popup-group"
              optionIdName="option-stocks-groups"
              @change="changedItemGroup"
            )

            showValidateError(
              v-show="veeErrors.has('itemGroupId')"
              :errorName="veeErrors.first('itemGroupId')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Stocks.popup_FormField_Tax') }}
          .control.control.form-item-select
            Icon(name="icon-down-arrow")


            customSelectInput(
              name="taxId",
              :optionData="Taxes"
              v-model="form.taxId"
              :data-vv-as="$t('Stocks.popup_FormField_Tax')"
              :error="veeErrors.has('taxId')"
              v-validate="'required|is_not:-1'"
              id="select-stocks-tax-group"
              optionIdName="option-stocks-tax"
            )

            showValidateError(
              v-show="veeErrors.has('taxId')"
              :errorName="veeErrors.first('taxId')"
            )
        div.Form-item(v-if="formType === 2")
          label.Form-item-label {{ $t('Stocks.popup_FormField_salesTax') }}
          .control.control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="salesTaxId",
              :optionData="Taxes"
              v-model="form.salesTaxId"
              :data-vv-as="$t('Stocks.popup_FormField_salesTax')"
              :error="veeErrors.has('salesTaxId')"
              id="select-stocks-sales-tax-group"
              optionIdName="option-stocks-sales-tax"
            )

            showValidateError(
              v-show="veeErrors.has('salesTaxId')"
              :errorName="veeErrors.first('salesTaxId')"
            )
        div.Form-item(v-if="formType === 2")
          label.Form-item-label {{ $t('Stocks.popup_FormField_priceType') }}
          .control.control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="priceType",
              :optionData="priceTypes"
              optionKey="value"
              optionIdKey="value"
              :isValueNumber="true"
              v-model="form.priceType"
              :data-vv-as="$t('Stocks.popup_FormField_priceType')"
              :error="veeErrors.has('priceType')"
              :hideDefault="true"
              :disabled="!checkPermission('PriceTypeEdit')"
              id="select-inventory-price-type"
            )

            showValidateError(
              v-show="veeErrors.has('priceType')"
              :errorName="veeErrors.first('priceType')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Stocks.popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="baseUnitId",
              :optionData="BaseUnitList"
              v-model="form.baseUnitId"
              :data-vv-as="$t('Stocks.popup_FormField_BaseUnit')"
              :error="veeErrors.has('baseUnitId')"
              :class="{ 'is-disabled': isEdit }"
              :disabled="isEdit"
              v-validate="'required|is_not:-1'"
              id="select-stocks-popup-baseunit"
              optionIdName="option-stocks-popup"
            )

            showValidateError(
              v-show="veeErrors.has('baseUnitId')"
              :errorName="veeErrors.first('baseUnitId')"
            )
        .Form-item.m-top-20(v-if="form.baseUnitId !== '-1'")
          label.Form-item-label {{ $t('Stocks.popup_kgQuantity') }}
          .control
            customNumberInput.txt-right-icon(
              name="kgQuantity"
              id="input-kgQuantity-number"
              :error="veeErrors.has('kgQuantity')"
              :data-vv-as="$t('Stocks.popup_kgQuantity')"
              v-model="form.kgQuantity"
              v-validate="{greater_than:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              :disabled="isUnitKg"
            )

            showValidateError(
              v-show="veeErrors.has('kgQuantity')"
              :errorName="veeErrors.first('kgQuantity')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Stocks.popup_FormField_Unit') }}
          .control.control.form-item-select
            Icon(name="icon-down-arrow")


            customSelectInput(
              name="unitId",
              :optionData="filteredUnitList"
              v-model="form.unitId"
              :data-vv-as="$t('Stocks.popup_FormField_Unit')"
              :error="veeErrors.has('unitId')"
              v-validate="'required|is_not:-1'"
              id="select-stocks-popup-group-unit"
              optionIdName="option-stocks-unit"
            )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

        div.Form-item(
          v-if="formType === 2 && checkPricePermission('StockItemsMenu')"
        )
          label.Form-item-label {{ $t('Stocks.popup_FormField_lastPurchasePrice') }}
          .control
            customNumberInput.txt-right-icon(
              name="lastPurchasePrice"
              id="input-stocks-form-item-number"
              :error="veeErrors.has('lastPurchasePrice')"
              :data-vv-as="$t('Stocks.popup_FormField_lastPurchasePrice')"
              v-validate="'min_value:0'"
              v-model="form.lastPurchasePrice"
              :isDelayInput="true"
            )

        div.Form-item(
          v-if="formType === 2"
        )
          label.Form-item-label {{ $t('Stocks.popup_FormField_StockItemNumber') }}
          .control
            customTextInput.txt-right-icon(
              name="itemNumber"
              id="input-stocks-form-item-number"
              :error="veeErrors.has('itemNumber')"
              :data-vv-as="$t('Stocks.popup_FormField_StockItemNumber')"
              v-validate="'verify_number'"
              v-model="form.itemNumber"
              :isDelayInput="true"
            )

            Button(
              id="btn-stock-popup-loading-number"
              variant="icon loading"
              tabindex="-1"
              v-show="isPendingNumber"
            )
              Loading(theme="disable")
            Button(
              id="btn-stock-popup-ok-number"
              variant="icon check"
              tabindex="-1"
              iconName="icon-check"
              iconClass="icon-check"
              :justIcon="true"
              v-show="isValidateNumber"
            )

            showValidateError(
              v-show="veeErrors.has('itemNumber')"
              :errorName="veeErrors.first('itemNumber')"
            )

        .Form-item.m-top-20(
          v-if="!isTypePurchase && formType === 2"
        )
          label.Form-item-label {{ $t('Stocks.popup_barcodeEntry') }}
          .control.barcode-input
            input.txt(
              id="input-price-quote-popup-field-barcode-stock-item-code"
              type="text"
              name="barcode"
              :class="{ 'is-danger': veeErrors.has(`barcode`) }"
              autocomplete="off"
              :placeholder="$t('PriceQuota.popupForm_Field_barcode_Placeholder')"
              :disabled="disableBarcodeInput"
              :data-vv-as="$t('PriceQuota.popupForm_Field_barcode')"
              v-model="barcode.barcode"
              v-validate="`numeric|verify_barcode|verify_weighted_barcode:${form.baseUnitId}`"
              @keydown.prevent.enter="addBarcode"
            )
            .barcode-actions
              Loading(
                v-if="isLoadingCheckBarcode"
                theme="disable"
              )
              Button(
                v-else
                type="button"
                size="small"
                :clearVariant="true"
                @click="addBarcode"
                :disabled="!barcode || disableBarcodeInput || disableBarcodeButton || veeErrors.has(`barcode`)"
              )
                svg.icon(:class="[barcode && !disableBarcodeInput && !veeErrors.has(`barcode`) ? 'c-success': 'c-gray']")
                  use(xlink:href="#icon-global-add")

            showValidateError(
              v-show="veeErrors.has('barcode')"
              :errorName="veeErrors.first('barcode')"
            )

          .barcodes
            span.barcodes-text(v-for="(barcode, index) in form.barcodeList") {{ barcode.barcode }}
              span.barcodes-text-remove(      
                @click="removeBarcode(index)"
              )
                Icon(name="icon-barcode-close")

        .Form-item.m-top-20(
          v-if="!isTypePurchase && formType === 2 && isEdit"
        )
          label.Form-item-label {{ $t('Stocks.popup_createBarcode') }}
          .control.create-barcode
            CustomCheckbox(
              id="input-stock-popup-field-barcode"
              :label="$t('Stocks.popupForm_GenerateWeightedBarcode')"
              :disabled="disableBarcodeInput || !isWeightedUnit(this.form.baseUnitId)"
              v-model="form.isWeightedBarcode"
              @change="changeBarcodeType"
            )
            Button(
              size="small"
              primary,
              @click="createBarcode"
              :disabled="disableBarcodeInput || disableBarcodeButton"
            )
              span {{ $t('Stocks.popup_createBarcode') }}

        div.Form-item.flex(v-if="formType === 2")
          .control
            label.Form-item-label {{ $t('Stocks.popup_estimatedCost') }}
            customNumberInput.txt-right-icon(
              name="estimatedCost"
              :disabled="!checkPricePermission('StockItemsMenu')"
              id="input-estimated-cost-number"
              :error="veeErrors.has('estimatedCost')"
              :data-vv-as="$t('Stocks.popup_estimatedCost')"
              v-model="form.estimatedCost"
              v-validate="{greater_than:0, decimal: 2, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
            )

            showValidateError(
              v-show="veeErrors.has('estimatedCost')"
              :errorName="veeErrors.first('estimatedCost')"
            )

          div.m-left-5
            label.Form-item-label {{ $t('Global.approvalLevel') }}
            customSelect(
              componentName="stock-form-approval-level"
              v-model="form.approvalLevel"
              :optionData="approvalLevels"
              :isDefaultTextActive="false"
            )

        div(v-if="formType === 2").m-top-25
          .m-bottom-10 {{ $t('Stocks.popup_getFromDeviationSettings') }}
          .price-deviation
            .Form-item
              .control
                label.Form-item-label {{ $t('Stocks.popup_minPriceDeviation') }}
                customNumberInput(
                  name="minPriceDeviation"
                  id="input-min-price-deviation"
                  placeholder=",00"
                  :disabled="checkPermission('StockItemPriceDeviation') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('minPriceDeviation')"
                  :data-vv-as="$t('Stocks.popup_minPriceDeviation')"
                  v-model="form.minPriceDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('minPriceDeviation')"
                  :errorName="veeErrors.first('minPriceDeviation')"
                )
            .Form-item.m-left-10
              .control
                label.Form-item-label {{ $t('Stocks.popup_maxPriceDeviation') }}
                customNumberInput(
                  name="maxPriceDeviation"
                  id="input-max-price-deviation"
                  placeholder=",00"
                  :disabled="checkPermission('StockItemPriceDeviation') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('maxPriceDeviation')"
                  :data-vv-as="$t('Stocks.popup_maxPriceDeviation')"
                  v-model="form.maxPriceDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('maxPriceDeviation')"
                  :errorName="veeErrors.first('maxPriceDeviation')"
                )
            .Form-item
              .control
                label.Form-item-label {{ $t('Stocks.popup_minQuantityDeviation') }}
                customNumberInput(
                  name="minQuantityDeviation"
                  id="input-min-quantity-deviation"
                  :isTypePercentage="true"
                  placeholder=',00'
                  :disabled="checkPermission('StockItemQuantityDeviation') === false"
                  :error="veeErrors.has('minQuantityDeviation')"
                  :data-vv-as="$t('Stocks.popup_minQuantityDeviation')"
                  v-model="form.minQuantityDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('minQuantityDeviation')"
                  :errorName="veeErrors.first('minQuantityDeviation')"
                )
            .Form-item.m-left-10
              .control
                label.Form-item-label {{ $t('Stocks.popup_maxQuantityDeviation') }}
                customNumberInput(
                  name="maxQuantityDeviation"
                  id="input-max-quantity-deviation"
                  :isTypePercentage="true"
                  :disabled="checkPermission('StockItemQuantityDeviation') === false"
                  placeholder=",00"
                  :error="veeErrors.has('maxQuantityDeviation')"
                  :data-vv-as="$t('Stocks.popup_maxQuantityDeviation')"
                  v-model="form.maxQuantityDeviation"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('maxQuantityDeviation')"
                  :errorName="veeErrors.first('maxQuantityDeviation')"
                )
        div(v-if="formType === 2").m-top-25
          .m-bottom-10.m-top-25 {{$t('Stocks.popup_getWithoutDeviationSettings')}}
          .price-deviation
            .Form-item
              .control
                label.Form-item-label {{ $t('Stocks.popup_minPriceDeviation') }}
                customNumberInput(
                  name="minPriceDeviationWithoutOrder"
                  id="input-min-price-devition-without-order"
                  placeholder=",00"
                  :disabled="checkPermission('StockItemPriceDeviationWithoutOrder') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('minPriceDeviationWithoutOrder')"
                  :data-vv-as="$t('Stocks.popup_minPriceDeviation')"
                  v-model="form.minPriceDeviationWithoutOrder"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('minPriceDeviationWithoutOrder')"
                  :errorName="veeErrors.first('minPriceDeviationWithoutOrder')"
                )
            .Form-item.m-left-10
              .control
                label.Form-item-label {{ $t('Stocks.popup_maxPriceDeviation') }}
                customNumberInput(
                  name="maxPriceDeviationWithoutOrder"
                  id="input-max-price-devition-without-order"
                  placeholder=",00"
                  :disabled="checkPermission('StockItemPriceDeviationWithoutOrder') === false"
                  :isTypePercentage="true"
                  :error="veeErrors.has('maxPriceDeviationWithoutOrder')"
                  :data-vv-as="$t('Stocks.popup_maxPriceDeviation')"
                  v-model="form.maxPriceDeviationWithoutOrder"
                  v-validate="'greater_than:-1|max_value:100'"
                )

                showValidateError(
                  v-show="veeErrors.has('maxPriceDeviationWithoutOrder')"
                  :errorName="veeErrors.first('maxPriceDeviationWithoutOrder')"
                )

        .Form-item(v-if="formType === 2").m-top-25
          label.Form-item-label {{ $t('Stocks.popupForm_Allergen') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
              id="select-stocks-allergen"
              name="allergen"
              :class="{'is-danger': veeErrors.has('allergen') }"
              :data-vv-as="$t('Stocks.popup_FormField_BaseUnit')"
              @change="addAllergen()"
              v-model="allergen"
            )
              option(
                id="option-allergen-unselected"
                value="-1"
              ) {{ $t('Stocks.popup_FormField_BaseUnit_Unselected') }}
              option(
                :id="`option-stocks-popup-allergen-${allergen.name}`"
                v-for="allergen in allergenListItems"
                :key="allergen.id"
                :value="allergen"
              ) {{ allergen.name }}

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('allergen')"
          ) {{ veeErrors.first('allergen') }}

          .barcodes
            span.barcodes-text.m-top-5(v-for="(allergen, index) in allergenListSelected") {{ allergen.name }}
              Button.barcodes-text-remove(
                type="button"
                size="small"
                iconName="icon-barcode-close"
                iconClass="icon-barcode-close"
                :clearVariant="true"
                @click="removeAllergen(index)"
              )

        .Form-item(v-if="formType === 2")
          label.Form-item-label {{ $t('Stocks.nutritionalValue') }}
          .control
            custom-search(
              name="nutritionName"
              component-name="form-nutrition-name"
              v-model="form.nutritionFoodName"
              :placeholder="$t('Recipes.detail_Popup_FormField_StockItemName_Placeholder')"
              :on-search="getOptions"
              :options="selectOptions"
              :loading="isSearchNutrition"
              label="name"
              @input="selectNutrition"
            )
            .selected-items.m-top-10.m-bottom-10
              Button(
                size="small"
                v-if="form.nutritionFoodName"
                id="selected-nutrition-list-item"
                @click.stop="removeNutrition"
              )
                span {{ form.nutritionFoodName }}
                Icon.m-left-5(
                  name="icon-popup-close"
                  id="selected-nutrition-list-item-icon"
                )
        .expiration-control.m-top-25
          .Form-item(v-if="formType === 2").expiration-control-shelf-life.m-right-20
            label.Form-item-label {{ $t('Stocks.popup_ShelfLife') }}
            .control
              customNumberInput.txt-right-icon(
                name="shelfLife"
                id="input-shelf-life-number"
                :error="veeErrors.has('shelfLife')"
                :data-vv-as="$t('Stocks.popup_ShelfLife')"
                v-model="form.shelfLife"
                v-validate="{min_value:0, decimal: $getConst('DECIMAL_FOR_QUANTITY')}"
              )
              showValidateError(
                v-show="veeErrors.has('shelfLife')"
                :errorName="veeErrors.first('shelfLife')"
              )
          .Form-item.exp-date-control(v-if="formType === 2").m-top-20.left-25
            .control
              CustomCheckbox(
                id="input-stock-popup-field-expiration-date-control"
                :label="$t('Stores.popupForm_ExpirationDateControl')"
                :disabled="isTypePurchase"
                :checked="form.expirationDateControlIsEnabled && !isTypePurchase"
                @change="changeExpirationDateControl")

        custom-inputs(
          :tableType="1",
          :isEdit="isEdit"
          v-model="customFields",
          ref="customInputs"
        )

    template(slot="footer")
      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
          id="checkbox-stock-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('Stocks.popup_Name') })"
          v-model="saveAndNew"
        )

      Button(
        id="btn-stock-popup-submit"
        variant="full"
        type="submit"
        form="form-stock"
        primary
        size="large"
        :disabled="isLoading"
        :isLoading="isLoading"
      )
        span(
          v-show="!isLoading"
        )
          template(
            v-if="isEdit"
          ) {{ $t('Stocks.popup_FinishEditButton') }}
          template(
            v-else
          ) {{ $t('Stocks.popup_FinishCreateButton') }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields, Validator } from 'vee-validate'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import SelectStockType from '@/view/global/multiselect-radio'
import CustomInputs from '@/view/global/optional-fields'
import customSelect from '@/view/global/custom-select'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'

export default {
  name: 'StockForm',
  mixins: [getQuantityFromBarcode],

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        id: null,
        name: null,
        itemGroupId: '-1',
        taxId: '-1',
        itemNumber: null,
        baseUnitId: '-1',
        unitId: '-1',
        barcodeList: [],
        type: 1,
        purchaseStockItemList: [],
        estimatedCost: null,
        kgQuantity: 1,
        approvalLevel: 0,
        nutritionFoodId: null,
        nutritionFoodName: null,
        salesTaxId: '-1',
        minPriceDeviation: null,
        maxPriceDeviation: null,
        minQuantityDeviation: null,
        maxQuantityDeviation: null,
        minPriceDeviationWithoutOrder: null,
        maxPriceDeviationWithoutOrder: null,
        priceType: 1,
        lastPurchasePrice: 0,
        expirationDateControlIsEnabled: false,
        shelfLife: null,
        isWeightedBarcode: false
      },
      typeData: [
        {
          text: this.$t('Stocks.type_fastStock'),
          value: 1
        },
        {
          text: this.$t('Stocks.type_detailStock'),
          value: 2
        }
      ],
      formType: 1,
      customFields: null,
      barcode: {
        barcode: null,
        unitId: -1
      },
      validBarcode: {
        stockItemName: '',
        unitName: ''
      },
      purchaseStockItemName: null,
      saveAndNew: true,
      stockTypeList: [
        {
          text: this.$t('Stocks.Item_Type_Stock'),
          value: 1
        },
        {
          text: this.$t('Stocks.Item_Type_Production'),
          value: 2
        },
        {
          text: this.$t('Stocks.Item_Type_Purchase'),
          value: 3
        }
      ],
      showPurchaseStockItemDropdown: false,
      allergen: null,
      allergenListSelected: [],
      approvalLevels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      selectOptions: [],
      priceTypes: [
        {
          value: 1,
          name: this.$t('Stocks.popup_priceType_dynamic')
        },
        {
          value: 2,
          name: this.$t('Stocks.popup_priceType_fixed')

        },
        {
          value: 3,
          name: this.$t('Stocks.popup_priceType_fixedPriceQuote')
        }
      ],
      disableBarcodeButton: false
    }
  },

  components: {
    SelectStockType,
    CustomInputs,
    customSelect,
  },

  watch: {
    'form.unitId' () {
      this.barcode.unitId = this.form.unitId
      this.reCheckBarcodeList()
    },
    'form.baseUnitId' (val) {
      if (val !== '-1' && !this.isEdit) {
        this.form.unitId = val
      } else if (val === '-1') {
        this.form.unitId = '-1'
      }
    }
  },

  created () {
    let self = this

    Validator.extend('verify_barcode', {
      // Custom validation message
      getMessage: () => self.$t('Stocks.checkBarcode', {stockItemName: self.validBarcode.stockItemName, unitName: self.validBarcode.unitName}),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let params = {
          Barcode: value,
          StockItemId: self.form.id
        }
        self.checkBarcode(params)
          .then(res => {
            if (res) {
              self.validBarcode.stockItemName = res.data.stockItemName
              self.validBarcode.unitName = res.data.unitName
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
      })
    })

    const verifyNumber = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            ItemNumber: value
          }
          if (self.isEdit) {
            params.Id = self.form.id
          }
          self.checkStockItemNumber(params).then(res => {
            if (res) {
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
        })
      }
    }
    const verifyNumberMessages = {
      tr: {
        messages: {
          verify_number: field =>
            'Girdiğiniz stok malı numarası sistemde mevcut, Lütfen farklı bir stok malı numrası giriniz.'
        }
      }
    }
    Validator.extend('verify_number', verifyNumber)
    Validator.localize(verifyNumberMessages)
  },

  async beforeMount () {
    if (this.isEdit) {
      this.$wait.start('settingStockFormData')
      await this.setData()
      this.$wait.end('settingStockFormData')
      this.$nextTick(() => { this.formFocus() })
    }
  },

  mounted () {
    if (!this.isEdit) {
      this.formFocus()
    }
  },
  computed: {
    ...mapFields({
      flagsName: 'name',
      flagsPurchaseName: 'purchaseStockItemName',
      flagsItemNumber: 'itemNumber'
    }),

    ...mapGetters('Tax', ['Taxes']),

    ...mapGetters('Group', ['allItemGroups']),

    ...mapGetters('Units', ['BaseUnitList', 'getRelatedUnitList', 'findUnit', 'kgBaseUnitId']),

    ...mapGetters('Stock', ['Page']),

    ...mapGetters('Allergen', ['AllergenList']),

    ...mapGetters('Settings', ['pricePermission', 'checkPermission', 'checkPricePermission']),

    isEmptyPurchaseStockItemList () {
      return this.form.purchaseStockItemList.length === 0
    },

    isSearchNutrition () {
      return this.$wait.is('getNutritionList')
    },

    stockItemNameValidationRules () {
      const url = this.isEdit
        ? `Stock/check/name?id=${this.$route.params.id},Name`
        : 'Stock/check/name,Name'
      return `required|max:128|very_singularity:${url}`
    },

    purchaseStockItemNameValidationRules () {
      const url = 'Stock/check/name,Name'
      return `max:64|very_singularity:${url}|is_not:${this.form.name}`
    },

    isLoadingCheckBarcode () {
      return this.$wait.is(['checkBarcode'])
    },

    isTypePurchase () {
      return this.form.type === 3
    },

    disableBarcodeInput () {
      return this.form.stockItem === null || this.form.unitId === '-1'
    },

    filteredUnitList () {
      return this.getRelatedUnitList(this.form.baseUnitId)
    },

    isUnitKg () {
      // Check if base unit is kg. Base unit ID's are constant
      if (this.findUnit(this.form.baseUnitId).id === this.kgBaseUnitId) {
        this.resetKgQuantityInput()
        return true
      } else {
        return false
      }
    },

    isLoading () {
      return (
        this.$wait.is(['Stock/updateStockItem', 'Stock/createStockItem', 'onSubmitStockHeader'])
      )
    },

    isFetchingStockData () {
      return this.$wait.is(['Stock/getStockItem', 'settingStockFormData'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isPendingPurchaseName () {
      return this.flagsPurchaseName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return (
          this.flagsName.valid &&
          this.form.name !== '' &&
          this.form.name !== null &&
          !this.isPendingName
        )
      }
    },

    isPendingNumber () {
      return this.flagsItemNumber.pending
    },

    isValidateNumber () {
      if (this.flagsItemNumber.required) {
        return this.flagsItemNumber.valid && !this.isPendingNumber
      } else {
        return (
          this.flagsItemNumber.valid &&
          this.form.itemNumber !== null &&
          this.form.itemNumber !== '' &&
          !this.isPendingNumber
        )
      }
    },

    isLoadingCheckPurchaseStockItemName () {
      return this.$wait.is('Global/verySingularityValidate') || this.isPendingPurchaseName
    },

    purchaseStockItemPlaceHolder () {
      return this.isEmptyPurchaseStockItemList
        ? this.$t('Stocks.popupForm_Field_purchaseStockItemName_Placeholder')
        : this.form.purchaseStockItemList.map(item => item.name).join(', ')
    },

    allergenListItems () {
      // Checking if item is selected and returns unselected items
      return this.AllergenList.filter(
        function (item) { return !this.some(selectedItems => selectedItems.id === item.id) },
        this.allergenListSelected
      )
    }
  },

  methods: {
    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Stock', [
      'getStockItem',
      'createStockItem',
      'updateStockItem',
      'checkStockItemNumber',
      'checkBarcode',
      'getNutritionList',
      'generateBarcode'
    ]),
    removeNutrition () {
      this.form.nutritionFoodName = null
      this.form.nutritionFoodId = null
    },

    changeExpirationDateControl () {
      this.form.expirationDateControlIsEnabled = !this.form.expirationDateControlIsEnabled
    },

    async createBarcode () {
      const payload = {
        barcodeType: this.form.isWeightedBarcode ? 1 : 2,
        stockItemId: this.form.id,
        unitId: this.form.baseUnitId
      }
      await this.generateBarcode(payload).then(res => {
        if (res) {
          this.form.barcodeList.push({barcode: res.data.barcodeNumber})
          this.disableBarcodeButton = true
        }
      })
    },

    changeBarcodeType (isChecked) {
      isChecked ? this.form.isWeightedBarcode = true : this.form.isWeightedBarcode = false  
      this.disableBarcodeButton = false
    },

    reCheckBarcodeList () {
      if (this.isWeightedUnit(this.form.unitId)) return
      let removeList = []

      this.form.barcodeList.map(barcode => {
        if (this.isWeighted(barcode.barcode)) {
          removeList.push(barcode.barcode)
        }
      })
      removeList.forEach(barcodeNumber => {
        const index = this.form.barcodeList.findIndex(i => i.barcode === barcodeNumber)
        this.removeBarcode(index)
      })
    },

    changePurchaseStockItemDropdown () {
      this.showPurchaseStockItemDropdown = !this.showPurchaseStockItemDropdown
    },
    openPurchaseStockItemDropdown () {
      this.showPurchaseStockItemDropdown = true
    },

    closePurchaseStockItemDropdown () {
      this.showPurchaseStockItemDropdown = false
    },

    async setData () {
      let stockItemId = this.$route.params.id
      await this.getStockItem(stockItemId)
        .then(async res => {
          this.form = await res.data.stockItem
          this.customFields = await res.data.stockItem.customMetaValues
          this.allergenListSelected = res.data.stockItem.allergenList
        })
    },

    changedItemGroup () {
      if (this.form.itemGroupId !== '-1') {
        const itemGroup = this.allItemGroups.find(item => item.id === this.form.itemGroupId)
        if (itemGroup.taxId) this.form.taxId = itemGroup.taxId
        if (itemGroup.approvalLevel) this.form.approvalLevel = itemGroup.approvalLevel
        if (itemGroup.salesTaxId) this.form.salesTaxId = itemGroup.salesTaxId
        this.form.minPriceDeviation = itemGroup.minPriceDeviation || null
        this.form.maxPriceDeviation = itemGroup.maxPriceDeviation || null
        this.form.minQuantityDeviation = itemGroup.minQuantityDeviation || null
        this.form.maxQuantityDeviation = itemGroup.maxQuantityDeviation || null
        this.form.maxPriceDeviationWithoutOrder = itemGroup.maxPriceDeviationWithoutOrder || null
        this.form.minPriceDeviationWithoutOrder = itemGroup.minPriceDeviationWithoutOrder || null
      }
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    // checkSameBarcodeToList() {
    //   let bool = true
    //   for (let i in this.form.barcodeList) {
    //     const oldBarcode = this.form.barcodeList[i].barcode
    //     if (oldBarcode === newBarcode) {
    //       bool = false
    //       break
    //     }
    //   }
    //   return bool
    // },

    addBarcode () {
      if (!this.isLoadingCheckBarcode && !!this.barcode.barcode) {
        if (!this.veeErrors.has('barcode')) {
          this.form.barcodeList.push(this.barcode)
          this.barcode = {
            barcode: null,
            unitId: this.form.unitId
          }
        } else {
          this.barcode = {
            barcode: null,
            unitId: this.form.unitId
          }
        }
      }
    },

    addAllergen () {
      if (this.allergen !== '-1') {
        this.allergenListSelected.push(this.allergen)
        this.allergen = '-1'
      }
    },

    removeAllergen (index) {
      this.allergenListSelected.splice(index, 1)
    },

    clearPurchaseStockItemName () {
      this.purchaseStockItemName = null
    },

    removeBarcode (index) {
      this.form.barcodeList.splice(index, 1)
      this.disableBarcodeButton = false
    },

    addPurchaseStockItem () {
      const isUsedBefore = this.form.purchaseStockItemList.find(item => item.name === this.purchaseStockItemName)
      const hasError = this.veeErrors.has('purchaseStockItemName')
      if (
        hasError ||
        this.isPendingPurchaseName ||
        this.purchaseStockItemName === this.form.name ||
        !this.purchaseStockItemName
      ) {
        return
      }
      const newPurchaseStockItem = {
        id: null,
        name: this.purchaseStockItemName
      }

      this.openPurchaseStockItemDropdown()
      if (!isUsedBefore) {
        this.form.purchaseStockItemList.unshift(newPurchaseStockItem)
        this.clearPurchaseStockItemName()
      }
    },

    removePurchaseStockItem (index) {
      this.form.purchaseStockItemList.splice(index, 1)
    },

    removePurchaseStockItemBarcode (item, index) {
      item.barcodeList.splice(index, 1)
    },

    resetKgQuantityInput () {
      this.form.kgQuantity = 1
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    resetForm () {
      this.form.id = null
      this.form.name = null
      this.form.itemNumber = null
      this.form.barcodeList = []
      this.form.purchaseStockItemList = []
      this.form.kgQuantity = 1
      this.allergenListSelected = []
      this.approvalLevel = 0
      this.form.nutritionFoodName = null
      this.form.nutritionFoodId = null
      this.form.minPriceDeviation = null
      this.form.maxPriceDeviation = null
      this.form.minQuantityDeviation = null
      this.form.maxQuantityDeviation = null
      this.form.minPriceDeviationWithoutOrder = null
      this.form.maxPriceDeviationWithoutOrder = null
      this.form.priceType = 1
    },

    getOptions (search) {
      const params = {
        name: search
      }
      this.getNutritionList({params})
        .then(res => {
          this.selectOptions = res.data.nutritionFoodList
        })
    },

    selectNutrition (item) {
      this.form.nutritionFoodName = item.name
      this.form.nutritionFoodId = item.id
    },

    onSubmitStockForm: vueWaitLoader(async function (isEdit) {
      if (!this.form.expirationDateControlIsEnabled) this.form.shelfLife = null
      if (!this.checkPermission('PriceTypeEdit')) this.$delete(this.form, 'priceType') // If user has no permission to edit price type
      if (!this.checkPricePermission('StockItemsMenu')) this.$delete(this.form, 'lastPurchasePrice') // If user has no permission to edit stock price
      if (
        Number(this.barcode.barcode) &&
        !this.veeErrors.has(`formPriceQuote.barcode`) &&
        !this.isLoadingCheckBarcode
      ) {
        this.addBarcode()
      }
      const isValidForm = await this.$validator.validateAll()
      const isValidCustomInputForm = await this.$refs.customInputs.$validator.validateAll()
      if (!isValidForm || !isValidCustomInputForm) return
      if (!this.form.estimatedCost) this.form.estimatedCost = null
      this.customFields.map(item => {
        item.value = item.value !== null ? item.value.toString() : null
      })
      if (!this.form.kgQuantity) this.form.kgQuantity = 1
      if (isEdit) {
        return this.submitEdit()
      }
      return this.submitNew()
    }, 'onSubmitStockHeader'),

    async submitNew () {
      // add item
      await this.createStockItem({
        ...this.form,
        customMetaValues: this.customFields,
        allergenList: this.allergenListSelected ? this.allergenListSelected.map(item => item.id) : []
      }).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', {
            name: this.form.name
          })
          this.notifyShow({ message })
          this.$emit('getList', this.Page.number)
          if (this.saveAndNew) {
            this.resetForm()
            this.formFocus()
            this.$refs.customInputs.createOptionalFormData()
            this.$nextTick(() => {
              this.$validator.reset()
              this.$refs.customInputs.$validator.reset()
            })
          } else {
            this.close()
          }
        }
      })
    },

    async submitEdit () {
      await this.updateStockItem({
        ...this.form,
        customMetaValues: this.customFields,
        allergenList: this.allergenListSelected ? this.allergenListSelected.map(item => item.id) : []
      }).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', {
            name: this.form.name
          })
          this.notifyShow({ message })
          this.$emit('getList', this.Page.number)
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.purchase-stock-item {
  display: flex;
  border-bottom: 1px solid $color-gray;
  padding-bottom: 20px;

  &-header-text,
  &-barcodes-text {
    background-color: $color-seashell;
    border-radius: $border-radius;
    border: 1px solid $color-success;
    font-size: $font-size-small;
    color: $color-success;
    max-width: max-content;
    font-weight: $font-weight-bold;

    .icon {
      margin-left: 10px;
      color: $color-success;
      width: 15px;
      height: 15px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
  }

  &-barcodes {
    display: flex;
    flex-direction: column;

    &-text {
      margin-left: 10px;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &-add {
      position: relative;
      height: 34px;
      margin-left: 10px;
      margin-top: 10px;
      width: max-content;

      &-input {
        height: 100%;
        color: $color-light;
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        padding: 10px 30px 10px 10px;
        width: 115px;

        &::placeholder {
          font-size: $font-size-small;
        }

        &.is-danger {
          border-color: $color-warning;
          background-color: rgba($color-warning, 0.05);
        }
      }

      &-button {
        position: absolute;
        color: $color-light;
        width: 15px;
        height: 15px;
        right: 15px;
        bottom: 13px;
        font-size: $font-size-small;
      }
    }
  }
}
$dropdown-height: 266px;
.purchase-dropdown {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: $dropdown-height;
  border: 1px solid $color-gray;
  border-top: none;
  border-radius: 0 0 $border-radius $border-radius;
  overflow-y: auto;

  &-item {
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 54px;
    padding: 10px;
    border-bottom: 1px solid $color-gray;

    &-remove {
      position: absolute;
      width: 15px;
      height: 15px;
      top: calc(50% - 15px);
      right: 15px;
      color: $color-warning;
    }
  }
  &-item:last-child {
    border-bottom: none;
  }

    &-item:hover {
      background-color: $color-gray;
    }
}

.clear-border-radius-bottom {
  border-radius: $border-radius $border-radius 0 0;
}

.purchase-dropdown-tranparency {
  z-index: 20;
  position: absolute;
  top: $dropdown-height;
  width: 100%;
  min-height: 54px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ffffff);
  border-radius: 0 0 $border-radius $border-radius;
  pointer-events: none;
}
.purchase-dropdown-shadow {
  box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.11);
}
.absolute {
  position: absolute;
  right: 10px !important;
  top: calc(50% - 10px);
}
.purchase-stock-item-input::placeholder {
  color: $color-dark;
}

.flex{
  display: flex !important;
}

:deep() .select-dropdown-custom-selected {
  height: 54px !important;
}
.price-deviation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .Form-item {
    flex: 45%;
    margin-top: 10px;
  }
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  Button {
    background: none !important;
    width: auto;
    padding-left: 0;
  }
}
:deep() .custom-number {
  padding-right: 0px !important;
}
.exp-date-control {
  display: flex;
  justify-content: flex-start;
}
.expiration-control {
  display: flex;
  align-items: center;
  &-shelf-life {
    width: 90px;
  }
}
.create-barcode {
  display: flex;
  justify-content: space-between;
}
</style>
