import BaseClass from '@/utils/baseclass'

export default class Stocks extends BaseClass {
  addItem(item) {
    let newItem = new Stock(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
      findItem.purchaseStockItemList = item.purchaseStockItemList || []
      findItem.type = item.type || 1
      findItem.itemNumber = item.itemNumber
      findItem.unitId = item.unitId || '-1'
      findItem.customMetaValues = item.customMetaValues || []
      findItem.estimatedCost = item.estimatedCost
      findItem.itemGroupName = item.itemGroupName
      findItem.taxName = item.taxName
      findItem.baseUnitName = item.baseUnitName
      findItem.stockItemType = item.stockItemType
      findItem.itemGroupId = item.itemGroupId || '-1'
      findItem.taxId = item.taxId || '-1'
      findItem.baseUnitId = item.baseUnitId || '-1'
      findItem.approvalLevel = item.approvalLevel
      findItem.nutritionFoodName = item.nutritionFoodName
      findItem.nutritionFoodId = item.nutritionFoodId
      findItem.salesTaxId = item.salesTaxId
      findItem.minPriceDeviation = item.minPriceDeviation
      findItem.maxPriceDeviation = item.maxPriceDeviation
      findItem.minQuantityDeviation = item.minQuantityDeviation
      findItem.maxQuantityDeviation = item.maxQuantityDeviation
      findItem.minPriceDeviationWithoutOrder = item.minPriceDeviationWithoutOrder
      findItem.maxPriceDeviationWithoutOrder = item.maxPriceDeviationWithoutOrder
      findItem.priceType = item.priceType
      findItem.lastPurchasePrice = item.lastPurchasePrice,
      findItem.lastPurchaseDate = item.lastPurchaseDatei
      findItem.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled,
      findItem.shelfLife = item.shelfLife
    }
  }
}

export class Stock {
  constructor(item) {
    this.id = item.id
    this.type = item.type || 1
    this.purchaseStockItemList = item.purchaseStockItemList || []
    this.itemNumberForPopup = 3
    this.customMetaValues = item.customMetaValues || []
    if (this.type === 3) {
      this.showPurchaseStockItemPopup = item.purchaseStockItemList.length > this.itemNumberForPopup
      this.purchaseStockItemNamesForPopup = item.purchaseStockItemList.map(item => item.name).join(', ')
      this.purchaseStockItemNames = this.setPurchaseStockItemNames(item) || null
    }
    this.name = item.name
    this.itemNumber = item.itemNumber
    this.unitId = item.unitId || '-1'
    this.barcodeList = item.barcodeList || []
    this.estimatedCost = item.estimatedCost
    this.kgQuantity = item.kgQuantity || 1
    this.allergenList = item.allergenList
    this.itemGroupName = item.itemGroupName
    this.taxName = item.taxName
    this.baseUnitName = item.baseUnitName
    this.stockItemType = item.stockItemType
    this.itemGroupId = item.itemGroupId || '-1'
    this.taxId = item.taxId || '-1'
    this.baseUnitId = item.baseUnitId || '-1'
    this.approvalLevel = item.approvalLevel
    this.nutritionFoodId = item.nutritionFoodId
    this.nutritionFoodName = item.nutritionFoodName
    this.salesTaxId = item.salesTaxId
    this.minPriceDeviation = item.minPriceDeviation
    this.maxPriceDeviation = item.maxPriceDeviation
    this.minQuantityDeviation = item.minQuantityDeviation
    this.maxQuantityDeviation = item.maxQuantityDeviation
    this.minPriceDeviationWithoutOrder = item.minPriceDeviationWithoutOrder
    this.maxPriceDeviationWithoutOrder = item.maxPriceDeviationWithoutOrder
    this.priceType = item.priceType
    this.lastPurchasePrice = item.lastPurchasePrice
    this.lastPurchaseDate = item.lastPurchaseDate,
    this.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled,
    this.shelfLife = item.shelfLife
  }

  setPurchaseStockItemNames() {
    let shortList = []
    for (const i in this.purchaseStockItemList) {
      if (i >= this.itemNumberForPopup) break
      shortList.push(this.purchaseStockItemList[i])
    }
    return this.showPurchaseStockItemPopup
    ? shortList.map(item => item.name).join(', ') + '...'
    : this.purchaseStockItemNamesForPopup
  }
}
